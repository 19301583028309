*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.container {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows:1fr auto;
  grid-template-areas: 
    "image sidebar"
    "slider sidebar";
  height: 100vh;
  width: 100vw;
  background-color:rgb(254,251,234);;
}

.main-image {
  grid-area: image;
  background-image: url('https://images.unsplash.com/photo-1681121881794-d3683ede874b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80');
  width: 100%;
  height: 100%;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}

.sidebar {
  grid-area: sidebar;
  background-color: lavender;
  border-left: 1px solid gray;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.sidebar-item {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: lavender;
  padding: 1rem;
  position: relative;
  transition: background-color 150ms;
}

.sidebar-item:hover, .sidebar-item:focus {
  background-color:rgb(254,251,234);
}

.sidebar-item.active {
  background-color: gold;
}

.sidebar-item::after {
  content: '';
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 0;
  height: 1px;
  background-color: gold;
}

.sidebar-item:last-child::after {
  display: none;
}

.slider-container {
  grid-area: slider;
  background-color: lavender;
  margin-top: 2rem;
  padding: 2rem;
  border-top: 1px solid gray;
}

.slider {
  width: 100%;
  cursor: pointer;
}